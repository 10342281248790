<template>
  <div>
    <b-card
      header-tag="header"
      no-body
      class="mb-0 table-card"
      style="border: 0"
    >
      <template v-slot:header>
        <b-row align-v="center">
          <b-col v-if="title" cols="auto">
            <strong>{{ title }}</strong>
          </b-col>
          <b-col>
            <div class="input-group input-group-flush">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fe fe-search"></i>
                </span>
              </div>
              <input
                v-model.trim="filter"
                class="form-control list-search"
                type="search"
                placeholder="Search"
              />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-table
        ref="table-all"
        responsive
        select-mode="single"
        :sticky-header="scrollable ? scrollHeight : false"
        :fields="fields"
        :items="items"
        @sort-changed="sortChanged"
        class="patient-table"
      >
        <template v-slot:cell(lastmessagesentbypatient)="data">
          {{
            parseDate(data.item.lastmessagesentbypatient, "MM/dd/yyyy h:mmaaa")
          }}
        </template>

        <template v-slot:cell(name)="data">
          <b-row no-gutters>
            <b-col cols="auto">
              <b-avatar :src="data.value.img"></b-avatar>
            </b-col>
            <b-col class="pl-3 tw-flex tw-items-center">
              <p class="mb-0">
                <router-link
                  v-if="
                    data.item.fullycancelleddatetime && type != 'da-cancelled'
                  "
                  :to="`/patients/profile/${data.value.id}`"
                  class="text-muted tw-cursor-pointer"
                >
                  <span class="tw-line-through">
                    {{ data.value.fullName }}
                  </span>
                </router-link>
                <router-link
                  v-else
                  :to="`/patients/profile/${data.value.id}`"
                  class="text-secondary tw-cursor-pointer"
                >
                  <span class="tw-underline">{{ data.value.fullName }}</span>
                </router-link>
                <small class="tw-text-[#BDBDBD] tw-inline-block ml-3">
                  <span>
                    {{ data.value.state }}
                    {{ parseDate(data.value.birthday, "MM/dd/yyyy", true) }}
                  </span>
                </small>
              </p>
            </b-col>
          </b-row>
        </template>

        <template v-slot:cell(action)="data">
          <div class="text-right tw-max-w-[200px]">
            <router-link :to="`/patients/profile/${data.item.value}`">
              {{ data.value == "profile" ? "View Profile" : data.value }}
            </router-link>
          </div>
        </template>

        <template v-slot:cell(action2)="data">
          <div class="text-right tw-max-w-[200px]">
            <router-link :to="`/patients/${data.item.value}/message`">
              <svg
                width="19px"
                height="19px"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.41177 6.5L7.75882 6.5C7.92451 6.5 8.05882 6.36569 8.05882 6.2L8.05882 5.46667C8.05882 5.30098 7.92451 5.16667 7.75882 5.16667L2.63444 5.16667C2.50221 5.16667 2.38558 5.0801 2.34729 4.95354L1.18971 1.1271C1.11536 0.88132 1.36405 0.6618 1.5987 0.766089L12.4018 5.56746C12.6363 5.67171 12.6402 6.00326 12.408 6.11287L1.65038 11.1929C1.40934 11.3067 1.15066 11.0731 1.23938 10.8218L2.41177 7.5"
                  stroke="#FF8D40"
                  stroke-width="0.8"
                />
              </svg>
              Message
            </router-link>
          </div>
        </template>

        <template v-slot:cell(action3)="data">
          <div class="text-right">
            <a
              class="tw-inline-block tw-mr-5 tw-cursor-pointer"
              @click="onPatientCancelDetails(data.item.name.id)"
            >
              <svg height="20px" width="20px" viewBox="0 0 11 7">
                <path
                  d="M0.759,3.526c0.307,-0.92 1.676,-2.726 4.62,-2.726c2.784,-0 4.16,1.615 4.561,2.568c-0.572,0.964 -2.359,2.632 -4.561,2.632c-1.374,0 -2.758,-0.425 -3.217,-0.883l-0.565,0.566c0.541,0.542 2.157,1.117 3.782,1.117c2.681,-0 4.822,-2.149 5.358,-3.221l0.074,-0.148l-0.052,-0.157c-0.36,-1.08 -1.924,-3.274 -5.38,-3.274c-3.455,0 -5.019,2.194 -5.379,3.274l0.759,0.252Zm4.62,-1.926c-0.993,0 -1.8,0.807 -1.8,1.8c0,0.993 0.807,1.8 1.8,1.8c0.994,-0 1.8,-0.807 1.8,-1.8c0,-0.993 -0.806,-1.8 -1.8,-1.8Zm0,0.8c0.552,-0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.551,-0 -1,-0.448 -1,-1c0,-0.552 0.449,-1 1,-1Z"
                  style="fill: #ff8d40"
                />
              </svg>
              View
            </a>
            <router-link :to="`/patients/${data.item.value}/message`">
              <svg
                width="19px"
                height="19px"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.41177 6.5L7.75882 6.5C7.92451 6.5 8.05882 6.36569 8.05882 6.2L8.05882 5.46667C8.05882 5.30098 7.92451 5.16667 7.75882 5.16667L2.63444 5.16667C2.50221 5.16667 2.38558 5.0801 2.34729 4.95354L1.18971 1.1271C1.11536 0.88132 1.36405 0.6618 1.5987 0.766089L12.4018 5.56746C12.6363 5.67171 12.6402 6.00326 12.408 6.11287L1.65038 11.1929C1.40934 11.3067 1.15066 11.0731 1.23938 10.8218L2.41177 7.5"
                  stroke="#FF8D40"
                  stroke-width="0.8"
                />
              </svg>
              Message
            </router-link>
          </div>
        </template>

        <template v-slot:cell(reassignmentstart)="data">
          {{ parseDate(data.item.reassignmentstart, "MM/dd/yyyy h:mmaaa") }}
        </template>

        <template v-slot:cell(reassignmentend)="data">
          {{ parseDate(data.item.reassignmentend, "MM/dd/yyyy h:mmaaa") }}
        </template>

        <template v-slot:cell(originaldoctorname2)="data">
          Reassigned from {{ data.item.originaldoctorname }}
        </template>
      </b-table>
    </b-card>

    <div
      class="
        tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-white
      "
    >
      <template>
        <b-pagination
          v-if="count"
          v-model="currentPage"
          :total-rows="count"
          :per-page="25"
          @page-click="handlePaginationClick"
        ></b-pagination>
      </template>
    </div>

    <b-modal id="cancelPatientInfo" hide-header centered hide-footer size="xl">
      <div style="position: absolute; top: 25px; left: 25px">
        <svg
          width="30"
          height="27"
          viewBox="0 0 30 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
            fill="#1A6A72"
          />
        </svg>
      </div>
      <div style="position: absolute; top: 25px; right: 15px">
        <b-button
          size="sm"
          variant="link"
          @click="$bvModal.hide('cancelPatientInfo')"
        >
          <b-icon
            icon="x-circle-fill"
            variant="primary"
            style="width: 20px; height: 20px"
          ></b-icon>
        </b-button>
      </div>
      <div class="py-6 lg:px-5">
        <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3">
          <h1 class="mb-4 tw-text-primary" style="line-height: 30px">
            Cancelled Patient
          </h1>
          <div
            class="lg:tw-col-span-2 lg:tw-grid lg:tw-grid-cols-2 lg:tw-pr-10"
          >
            <div>
              <small class="tw-text-primary">Patient</small>
              <p class="tw-text-lg">{{ cancelDetails.patientname }}</p>
            </div>
            <div>
              <small class="tw-text-primary">Onboarded by</small>
              <p class="tw-text-lg">{{ cancelDetails.onboardedby || "-" }}</p>
            </div>
            <div>
              <small class="tw-text-primary">Onboarded</small>
              <p class="tw-text-lg">{{ cancelDetails.dateonboarded }}</p>
            </div>
            <div>
              <small class="tw-text-primary">Cancelled</small>
              <p class="tw-text-lg">
                {{ cancelDetails.fullycancelleddatetime }}
              </p>
            </div>
            <div
              class="tw-col-span-2"
              v-if="cancelDetails.cancelledPrescriptions.length > 0"
            >
              <small class="tw-text-primary">Last Subscriptions</small>
              <div class="tw-max-h-[300px] tw-overflow-y-auto">
                <div
                  class="tw-text-lg lg:tw-grid tw-grid-cols-4"
                  v-for="(
                    prescription, i
                  ) in cancelDetails.cancelledPrescriptions"
                  :key="i"
                >
                  <div class="tw-col-span-2 mb-2">
                    <span>{{ prescription.medicinename }}</span>
                    <!-- <span class="text-muted"> | 2 months left</span> -->
                  </div>
                  <p class="tw-mb-0 lg:tw-text-right tw-col-span-1">
                    {{ prescription.refills }}
                  </p>
                  <p class="lg:tw-text-right tw-col-span-1">
                    ${{ prescription.totalamountwithtax }}
                  </p>
                </div>
              </div>
            </div>
            <div class="tw-col-span-2">
              <small class="tw-text-primary">Cancelled by</small>
              <p class="tw-text-lg">{{ cancelDetails.cancelledby || "-" }}</p>
            </div>
            <div class="tw-col-span-2">
              <small class="tw-text-primary">Reason</small>
              <p class="tw-text-lg">{{ cancelDetails.cancellationreason }}</p>
            </div>
            <div class="tw-col-span-2">
              <small class="tw-text-primary">Notes</small>
              <p class="tw-text-lg">
                {{ cancelDetails.cancellationnotes }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { PatientService } from "@/services/patient.service";
import { format, fromUnixTime } from "date-fns";

export default {
  props: {
    title: {
      type: String,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
    fields: Array,
    type: String,
  },
  data() {
    return {
      filter: "",
      search: "",
      items: [],
      count: 0,
      currentPage: 1,
      sortDesc: "DESC",
      sortBy: "lastupdateactivity",

      cancelDetails: {
        patientname: "",
        doctor: "",
        dateonboarded: "",
        fullycancelleddatetime: "",
        cancelledby: "",
        cancellationreason: "",
        cancellationnotes: "",
        cancelledPrescriptions: [],
      },
    };
  },
  mounted() {
    this.getPatients();
  },
  methods: {
    async getPatients(
      page = "",
      search = "",
      sortDirection = "DESC",
      sortField = "lastupdateactivity"
    ) {
      this.items = [];
      const { data } = await PatientService.getPatientsV2(
        this.type,
        page,
        search,
        sortDirection,
        sortField
      );
      this.items = this.convertPatientData(data.items);
      this.count = Number(data.count);
    },
    searchPatient: _.debounce(async function (search) {
      this.count = 0;
      this.currentPage = 1;
      this.search = search;

      await this.getPatients(
        this.currentPage,
        this.search,
        this.sortDesc,
        this.sortBy
      );
    }, 350),
    handlePaginationClick(event, pageNumber) {
      this.currentPage = pageNumber;
      this.getPatients(pageNumber, this.search, this.sortDesc, this.sortBy);
    },
    sortChanged(e) {
      switch (e.sortBy) {
        case "name":
          this.sortBy = "firstname";
          break;
        default:
          this.sortBy = e.sortBy;
          break;
      }

      this.sortDesc = e.sortDesc ? "DESC" : "ASC";

      this.currentPage = 1;
      this.getPatients(
        this.currentPage,
        this.search,
        this.sortDesc,
        this.sortBy
      );
    },
    parseDate(date, stringFormat, isDate) {
      if (date && typeof date !== "object") {
        return format(
          isDate ? new Date(date) : fromUnixTime(date),
          stringFormat
        );
      }
    },
    convertPatientData(records) {
      return records.map((patient) => ({
        value: patient.id,
        name: {
          img: patient.profileUrl,
          fullName: `${patient.firstname} ${patient.lastname}`,
          birthday: patient.birthday,
          state: patient.state,
          id: patient.id,
        },
        lastmessagesentbypatient: patient.lastmessagesentbypatient,
        lastmessagebypatient: patient.lastmessagebypatient,
        fullycancelleddatetime: patient.fullycancelleddatetime,
        cancellationnotes: patient.cancellationnotes,
        cancellationreason: patient.cancellationreason,
        originaldoctorname: patient.originaldoctorname
          ? `Dr. ${patient.originaldoctorname}`
          : "-",
        reassignmentstart: patient.reassignmentstart,
        reassignmentend: patient.reassignmentend,
        action: "profile",
      }));
    },
    async onPatientCancelDetails(patientId) {
      const patient = await this.$store.dispatch("patients/getPatientInfo", {
        patientId,
      });
      const prescriptions = await this.$store.dispatch(
        "prescription/getPrescriptionOrderPerPatient",
        {
          patientid: patientId,
          nextpage: "all",
        }
      );
      const cancelledPrescriptions = prescriptions
        .filter((prescription) => {
          return prescription.prescriptionstatus === "CANCELLED";
        })
        .map((prescription) => ({
          medicinename: prescription.medicinename,
          totalamountwithtax: prescription.totalamountwithtax,
          refills:
            prescription.refills && prescription.paidrefills
              ? `${prescription.refills - 1 - (prescription.paidrefills - 1)}/${
                  prescription.refills - 1
                } refills`
              : `${prescription.refillinput} refills`,
        }));
      this.cancelDetails = {
        patientname: `${patient.firstname} ${patient.lastname}`,
        doctor: "",
        dateonboarded: this.parseDate(patient.dateonboarded, "MMMM d, yyyy"),
        fullycancelleddatetime: this.parseDate(
          patient.fullycancelleddatetime,
          "MMMM d, yyyy"
        ),
        cancelledby: patient.cancelledbyusername
          ? patient.cancelledbyusertype === "DOCTOR"
            ? `Dr. ${patient.cancelledbyusername}`
            : patient.cancelledbyusername
          : "",
        cancellationreason: patient.cancellationreason,
        cancellationnotes: patient.cancellationnotes,
        cancelledPrescriptions,
      };
      this.$bvModal.show("cancelPatientInfo");
    },
  },
  watch: {
    filter(val) {
      this.searchPatient(val);
    },
  },
};
</script>

<style scoped>
::v-deep .table thead th,
::v-deep .table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: rgb(243, 247, 248);
  border-bottom: 1px solid #1a6a72;
}

::v-deep .table.b-table > thead > tr > .table-b-table-default {
  color: #828282;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0.75px;
}

::v-deep .table thead th:focus {
  user-select: none;
}
</style>

<style lang="scss">
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: $primary;
  color: $white;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  border-top: 0;
}

.table thead th {
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.card.table-card {
  border: 0;
  background-color: rgb(243, 247, 248);
}

.table thead th {
  border: 0;
}

.table tbody td {
  border-bottom: 0;
  border-top: 1px solid rgba(26, 106, 114, 0.2);
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  background-color: $green;
}

.dot-red {
  background-color: $red;
}

.dot-yellow {
  background-color: #f5d83e;
}
</style>
