<template>
  <div>
    <Main
      scroll-height="calc(100vh - 400px)"
      scrollable
      :fields="fields"
      :type="type"
    />
  </div>
</template>

<script>
import Main from "@/components/tables/patients/PatientDashboardCommon.vue";

export default {
  components: {
    Main,
  },
  props: {
    title: {
      type: String,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      fields: [
        { key: "name", sortable: false },
        {
          key: "originaldoctorname2",
          label: "Original Doctor",
          sortable: false,
        },
        { key: "reassignmentstart", label: "Start Date", sortable: false },
        { key: "reassignmentend", label: "End Date", sortable: false },
      ],
      type: "da-covered",
    };
  },
};
</script>
