<template>
  <b-row class="tw-top-20 tw-relative">
    <b-col cols="12" class="tw-py-4 md:tw-py-10">
      <div class="tw-flex tw-justify-between">
        <div class="tw-flex tw-flex-wrap">
          <h1
            class="tw-mb-2 md:tw-mb-0 text-primary"
            style="font-size: 22px; font-weight: 500"
          >
            Patients
          </h1>
          <div
            class="
              tw-flex tw-items-center
              md:tw-ml-5
              tw-flex-wrap
              md:tw-space-x-4
            "
          >
            <div
              @click="selectedStatus = 'ALL'"
              class="tw-py-2 tw-px-3 md:tw-p-0 text-muted"
              style="cursor: pointer"
            >
              <span :class="selectedStatus === 'ALL' ? 'text-dark-cyan' : ''">
                All
              </span>
              <span
                class="status-pill"
                :class="selectedStatus === 'ALL' ? 'pill-dark-cyan' : ''"
              >
                {{ statuscount["da-all"] }}
              </span>
            </div>
            <div
              @click="selectedStatus = 'ACTIVE'"
              class="tw-py-2 tw-px-3 md:tw-p-0 text-muted"
              style="cursor: pointer"
            >
              <span
                :class="selectedStatus === 'ACTIVE' ? 'text-dark-cyan' : ''"
              >
                Active
              </span>
              <span
                class="status-pill"
                :class="selectedStatus === 'ACTIVE' ? 'pill-dark-cyan' : ''"
              >
                {{ statuscount["da-active"] }}
              </span>
            </div>
            <div
              @click="selectedStatus = 'NEEDS REPLY'"
              class="tw-py-2 tw-px-3 md:tw-p-0 text-muted"
              style="cursor: pointer"
            >
              <span
                :class="
                  selectedStatus === 'NEEDS REPLY' ? 'text-dark-cyan' : ''
                "
              >
                Needs Reply
              </span>
              <span
                class="status-pill"
                :class="
                  selectedStatus === 'NEEDS REPLY' ? 'pill-dark-cyan' : ''
                "
              >
                {{ statuscount["da-needsreply"] }}
              </span>
            </div>
            <div
              @click="selectedStatus = 'CLEARED'"
              class="tw-py-2 tw-px-3 md:tw-p-0 text-muted"
              style="cursor: pointer"
            >
              <span
                :class="selectedStatus === 'CLEARED' ? 'text-dark-cyan' : ''"
              >
                Cleared
              </span>
              <span
                class="status-pill"
                :class="selectedStatus === 'CLEARED' ? 'pill-dark-cyan' : ''"
              >
                {{ statuscount["da-ignored"] }}
              </span>
            </div>
            <div
              @click="selectedStatus = 'CANCELLED'"
              class="tw-py-2 tw-px-3 md:tw-p-0 text-muted"
              style="cursor: pointer"
            >
              <span
                :class="selectedStatus === 'CANCELLED' ? 'text-dark-cyan' : ''"
              >
                Cancelled
              </span>
              <span
                class="status-pill"
                :class="selectedStatus === 'CANCELLED' ? 'pill-dark-cyan' : ''"
              >
                {{ statuscount["da-cancelled"] }}
              </span>
            </div>
            <div
              @click="selectedStatus = 'COVERED'"
              class="tw-py-2 tw-px-3 md:tw-p-0 text-muted"
              style="cursor: pointer"
            >
              <span
                :class="selectedStatus === 'COVERED' ? 'text-dark-cyan' : ''"
              >
                Covered Patients
              </span>
              <span
                class="status-pill"
                :class="selectedStatus === 'COVERED' ? 'pill-dark-cyan' : ''"
              >
                {{ statuscount["da-covered"] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="12">
      <table-all v-if="selectedStatus === 'ALL'" />
      <table-active v-if="selectedStatus === 'ACTIVE'" />
      <table-needs-reply v-if="selectedStatus === 'NEEDS REPLY'" />
      <table-cleared v-if="selectedStatus === 'CLEARED'" />
      <table-cancelled v-if="selectedStatus === 'CANCELLED'" />
      <table-covered v-if="selectedStatus === 'COVERED'" />
    </b-col>
  </b-row>
</template>

<script>
import TableAll from "@/components/tables/patients/TableAll.vue";
import TableActive from "@/components/tables/patients/TableActive.vue";
import TableNeedsReply from "@/components/tables/patients/TableNeedsReply.vue";
import TableCleared from "@/components/tables/patients/TableCleared.vue";
import TableCancelled from "@/components/tables/patients/TableCancelled.vue";
import TableCovered from "@/components/tables/patients/TableCovered.vue";

export default {
  name: "patients",
  components: {
    TableAll,
    TableActive,
    TableNeedsReply,
    TableCleared,
    TableCancelled,
    TableCovered,
  },
  data() {
    return {
      isBusy: true,
      activeItem: "All",
      selected: "list",
      options: [
        { icon: "list", value: "list" },
        { icon: "grid", value: "grid" },
      ],

      selectedStatus: "ALL",
      statuscount: {
        "da-all": 0,
        "da-active": 0,
        "da-needsreply": 0,
        "da-cancelled": 0,
        "da-covered": 0,
        "da-ignored": 0,
      },
    };
  },
  async created() {
    window.analytics.page();
    const data = await this.$store.dispatch("patients/getPatientStatusCount");
    this.statuscount = data;
  },
};
</script>

<style lang="scss" scoped>
.status-pill {
  background: #f2f2f2;
  border-radius: 999px;
  padding: 4px 8px;
  margin-left: 0.25rem;
  font-size: 0.75rem;
}

.pill-purple {
  background: #a783ff;
  color: #edffe1;
}

.pill-monarch {
  background: #ff8d40;
  color: #ffecca;
}

.pill-dark-cyan {
  background: $primary;
  color: #ccffff;
}

.text-purple {
  color: #a783ff;
}

.text-monarch {
  color: #ff8d40;
}

.text-dark-cyan {
  color: $primary;
}
</style>
