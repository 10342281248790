var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PatientDashboardCommon", {
        attrs: {
          "scroll-height": "calc(100vh - 400px)",
          scrollable: "",
          fields: _vm.fields,
          type: _vm.type,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }