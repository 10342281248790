<template>
  <div>
    <PatientDashboardCommon
      scroll-height="calc(100vh - 400px)"
      scrollable
      :fields="fields"
      :type="type"
    />
  </div>
</template>

<script>
import PatientDashboardCommon from "@/components/tables/patients/PatientDashboardCommon.vue";

export default {
  components: {
    PatientDashboardCommon,
  },
  props: {
    title: {
      type: String,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      fields: [
        { key: "name", sortable: true },
        {
          key: "lastmessagesentbypatient",
          label: "Last Message",
          sortable: false,
        },
        { key: "originaldoctorname", label: "Covered From", sortable: false },
        { key: "action", label: "", sortable: false },
      ],
      type: "da-all",
    };
  },
};
</script>
