var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          staticClass: "mb-0 table-card",
          staticStyle: { border: "0" },
          attrs: { "header-tag": "header", "no-body": "" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _vm.title
                        ? _c("b-col", { attrs: { cols: "auto" } }, [
                            _c("strong", [_vm._v(_vm._s(_vm.title))]),
                          ])
                        : _vm._e(),
                      _c("b-col", [
                        _c(
                          "div",
                          { staticClass: "input-group input-group-flush" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", { staticClass: "fe fe-search" }),
                              ]),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.filter,
                                  expression: "filter",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control list-search",
                              attrs: { type: "search", placeholder: "Search" },
                              domProps: { value: _vm.filter },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.filter = $event.target.value.trim()
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table", {
            ref: "table-all",
            staticClass: "patient-table",
            attrs: {
              responsive: "",
              "select-mode": "single",
              "sticky-header": _vm.scrollable ? _vm.scrollHeight : false,
              fields: _vm.fields,
              items: _vm.items,
            },
            on: { "sort-changed": _vm.sortChanged },
            scopedSlots: _vm._u([
              {
                key: "cell(lastmessagesentbypatient)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseDate(
                            data.item.lastmessagesentbypatient,
                            "MM/dd/yyyy h:mmaaa"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(name)",
                fn: function (data) {
                  return [
                    _c(
                      "b-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "auto" } },
                          [_c("b-avatar", { attrs: { src: data.value.img } })],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "pl-3 tw-flex tw-items-center" },
                          [
                            _c(
                              "p",
                              { staticClass: "mb-0" },
                              [
                                data.item.fullycancelleddatetime &&
                                _vm.type != "da-cancelled"
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "text-muted tw-cursor-pointer",
                                        attrs: {
                                          to: `/patients/profile/${data.value.id}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tw-line-through" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.value.fullName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "text-secondary tw-cursor-pointer",
                                        attrs: {
                                          to: `/patients/profile/${data.value.id}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tw-underline" },
                                          [_vm._v(_vm._s(data.value.fullName))]
                                        ),
                                      ]
                                    ),
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "tw-text-[#BDBDBD] tw-inline-block ml-3",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.value.state) +
                                          " " +
                                          _vm._s(
                                            _vm.parseDate(
                                              data.value.birthday,
                                              "MM/dd/yyyy",
                                              true
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-right tw-max-w-[200px]" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/patients/profile/${data.item.value}`,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.value == "profile"
                                    ? "View Profile"
                                    : data.value
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(action2)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-right tw-max-w-[200px]" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/patients/${data.item.value}/message`,
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "19px",
                                  height: "19px",
                                  viewBox: "0 0 13 12",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M2.41177 6.5L7.75882 6.5C7.92451 6.5 8.05882 6.36569 8.05882 6.2L8.05882 5.46667C8.05882 5.30098 7.92451 5.16667 7.75882 5.16667L2.63444 5.16667C2.50221 5.16667 2.38558 5.0801 2.34729 4.95354L1.18971 1.1271C1.11536 0.88132 1.36405 0.6618 1.5987 0.766089L12.4018 5.56746C12.6363 5.67171 12.6402 6.00326 12.408 6.11287L1.65038 11.1929C1.40934 11.3067 1.15066 11.0731 1.23938 10.8218L2.41177 7.5",
                                    stroke: "#FF8D40",
                                    "stroke-width": "0.8",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" Message "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(action3)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "tw-inline-block tw-mr-5 tw-cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.onPatientCancelDetails(
                                  data.item.name.id
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  height: "20px",
                                  width: "20px",
                                  viewBox: "0 0 11 7",
                                },
                              },
                              [
                                _c("path", {
                                  staticStyle: { fill: "#ff8d40" },
                                  attrs: {
                                    d: "M0.759,3.526c0.307,-0.92 1.676,-2.726 4.62,-2.726c2.784,-0 4.16,1.615 4.561,2.568c-0.572,0.964 -2.359,2.632 -4.561,2.632c-1.374,0 -2.758,-0.425 -3.217,-0.883l-0.565,0.566c0.541,0.542 2.157,1.117 3.782,1.117c2.681,-0 4.822,-2.149 5.358,-3.221l0.074,-0.148l-0.052,-0.157c-0.36,-1.08 -1.924,-3.274 -5.38,-3.274c-3.455,0 -5.019,2.194 -5.379,3.274l0.759,0.252Zm4.62,-1.926c-0.993,0 -1.8,0.807 -1.8,1.8c0,0.993 0.807,1.8 1.8,1.8c0.994,-0 1.8,-0.807 1.8,-1.8c0,-0.993 -0.806,-1.8 -1.8,-1.8Zm0,0.8c0.552,-0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.551,-0 -1,-0.448 -1,-1c0,-0.552 0.449,-1 1,-1Z",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" View "),
                          ]
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/patients/${data.item.value}/message`,
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "19px",
                                  height: "19px",
                                  viewBox: "0 0 13 12",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M2.41177 6.5L7.75882 6.5C7.92451 6.5 8.05882 6.36569 8.05882 6.2L8.05882 5.46667C8.05882 5.30098 7.92451 5.16667 7.75882 5.16667L2.63444 5.16667C2.50221 5.16667 2.38558 5.0801 2.34729 4.95354L1.18971 1.1271C1.11536 0.88132 1.36405 0.6618 1.5987 0.766089L12.4018 5.56746C12.6363 5.67171 12.6402 6.00326 12.408 6.11287L1.65038 11.1929C1.40934 11.3067 1.15066 11.0731 1.23938 10.8218L2.41177 7.5",
                                    stroke: "#FF8D40",
                                    "stroke-width": "0.8",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" Message "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(reassignmentstart)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseDate(
                            data.item.reassignmentstart,
                            "MM/dd/yyyy h:mmaaa"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(reassignmentend)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseDate(
                            data.item.reassignmentend,
                            "MM/dd/yyyy h:mmaaa"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(originaldoctorname2)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " Reassigned from " +
                        _vm._s(data.item.originaldoctorname) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-white",
        },
        [
          [
            _vm.count
              ? _c("b-pagination", {
                  attrs: { "total-rows": _vm.count, "per-page": 25 },
                  on: { "page-click": _vm.handlePaginationClick },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                })
              : _vm._e(),
          ],
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancelPatientInfo",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "xl",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { position: "absolute", top: "25px", left: "25px" },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "27",
                    viewBox: "0 0 30 27",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                      fill: "#1A6A72",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: { position: "absolute", top: "25px", right: "15px" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "link" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("cancelPatientInfo")
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { icon: "x-circle-fill", variant: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "py-6 lg:px-5" }, [
            _c(
              "div",
              { staticClass: "tw-grid tw-grid-cols-1 lg:tw-grid-cols-3" },
              [
                _c(
                  "h1",
                  {
                    staticClass: "mb-4 tw-text-primary",
                    staticStyle: { "line-height": "30px" },
                  },
                  [_vm._v(" Cancelled Patient ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "lg:tw-col-span-2 lg:tw-grid lg:tw-grid-cols-2 lg:tw-pr-10",
                  },
                  [
                    _c("div", [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Patient"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(_vm._s(_vm.cancelDetails.patientname)),
                      ]),
                    ]),
                    _c("div", [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Onboarded by"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(_vm._s(_vm.cancelDetails.onboardedby || "-")),
                      ]),
                    ]),
                    _c("div", [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Onboarded"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(_vm._s(_vm.cancelDetails.dateonboarded)),
                      ]),
                    ]),
                    _c("div", [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Cancelled"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.cancelDetails.fullycancelleddatetime) +
                            " "
                        ),
                      ]),
                    ]),
                    _vm.cancelDetails.cancelledPrescriptions.length > 0
                      ? _c("div", { staticClass: "tw-col-span-2" }, [
                          _c("small", { staticClass: "tw-text-primary" }, [
                            _vm._v("Last Subscriptions"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-max-h-[300px] tw-overflow-y-auto",
                            },
                            _vm._l(
                              _vm.cancelDetails.cancelledPrescriptions,
                              function (prescription, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass:
                                      "tw-text-lg lg:tw-grid tw-grid-cols-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tw-col-span-2 mb-2" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(prescription.medicinename)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "tw-mb-0 lg:tw-text-right tw-col-span-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(prescription.refills) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "lg:tw-text-right tw-col-span-1",
                                      },
                                      [
                                        _vm._v(
                                          " $" +
                                            _vm._s(
                                              prescription.totalamountwithtax
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "tw-col-span-2" }, [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Cancelled by"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(_vm._s(_vm.cancelDetails.cancelledby || "-")),
                      ]),
                    ]),
                    _c("div", { staticClass: "tw-col-span-2" }, [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Reason"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(_vm._s(_vm.cancelDetails.cancellationreason)),
                      ]),
                    ]),
                    _c("div", { staticClass: "tw-col-span-2" }, [
                      _c("small", { staticClass: "tw-text-primary" }, [
                        _vm._v("Notes"),
                      ]),
                      _c("p", { staticClass: "tw-text-lg" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.cancelDetails.cancellationnotes) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }