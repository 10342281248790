var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "tw-top-20 tw-relative" },
    [
      _c(
        "b-col",
        { staticClass: "tw-py-4 md:tw-py-10", attrs: { cols: "12" } },
        [
          _c("div", { staticClass: "tw-flex tw-justify-between" }, [
            _c("div", { staticClass: "tw-flex tw-flex-wrap" }, [
              _c(
                "h1",
                {
                  staticClass: "tw-mb-2 md:tw-mb-0 text-primary",
                  staticStyle: { "font-size": "22px", "font-weight": "500" },
                },
                [_vm._v(" Patients ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-items-center md:tw-ml-5 tw-flex-wrap md:tw-space-x-4",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tw-py-2 tw-px-3 md:tw-p-0 text-muted",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.selectedStatus = "ALL"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.selectedStatus === "ALL"
                              ? "text-dark-cyan"
                              : "",
                        },
                        [_vm._v(" All ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-pill",
                          class:
                            _vm.selectedStatus === "ALL"
                              ? "pill-dark-cyan"
                              : "",
                        },
                        [_vm._v(" " + _vm._s(_vm.statuscount["da-all"]) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-py-2 tw-px-3 md:tw-p-0 text-muted",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.selectedStatus = "ACTIVE"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.selectedStatus === "ACTIVE"
                              ? "text-dark-cyan"
                              : "",
                        },
                        [_vm._v(" Active ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-pill",
                          class:
                            _vm.selectedStatus === "ACTIVE"
                              ? "pill-dark-cyan"
                              : "",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.statuscount["da-active"]) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-py-2 tw-px-3 md:tw-p-0 text-muted",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.selectedStatus = "NEEDS REPLY"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.selectedStatus === "NEEDS REPLY"
                              ? "text-dark-cyan"
                              : "",
                        },
                        [_vm._v(" Needs Reply ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-pill",
                          class:
                            _vm.selectedStatus === "NEEDS REPLY"
                              ? "pill-dark-cyan"
                              : "",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.statuscount["da-needsreply"]) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-py-2 tw-px-3 md:tw-p-0 text-muted",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.selectedStatus = "CLEARED"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.selectedStatus === "CLEARED"
                              ? "text-dark-cyan"
                              : "",
                        },
                        [_vm._v(" Cleared ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-pill",
                          class:
                            _vm.selectedStatus === "CLEARED"
                              ? "pill-dark-cyan"
                              : "",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.statuscount["da-ignored"]) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-py-2 tw-px-3 md:tw-p-0 text-muted",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.selectedStatus = "CANCELLED"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.selectedStatus === "CANCELLED"
                              ? "text-dark-cyan"
                              : "",
                        },
                        [_vm._v(" Cancelled ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-pill",
                          class:
                            _vm.selectedStatus === "CANCELLED"
                              ? "pill-dark-cyan"
                              : "",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.statuscount["da-cancelled"]) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-py-2 tw-px-3 md:tw-p-0 text-muted",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.selectedStatus = "COVERED"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.selectedStatus === "COVERED"
                              ? "text-dark-cyan"
                              : "",
                        },
                        [_vm._v(" Covered Patients ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "status-pill",
                          class:
                            _vm.selectedStatus === "COVERED"
                              ? "pill-dark-cyan"
                              : "",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.statuscount["da-covered"]) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _vm.selectedStatus === "ALL" ? _c("table-all") : _vm._e(),
          _vm.selectedStatus === "ACTIVE" ? _c("table-active") : _vm._e(),
          _vm.selectedStatus === "NEEDS REPLY"
            ? _c("table-needs-reply")
            : _vm._e(),
          _vm.selectedStatus === "CLEARED" ? _c("table-cleared") : _vm._e(),
          _vm.selectedStatus === "CANCELLED" ? _c("table-cancelled") : _vm._e(),
          _vm.selectedStatus === "COVERED" ? _c("table-covered") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }